const role = localStorage.getItem('role');

const initialState = {
  logs: [],
  log_select:
    role == 'action_owner' || role == 'vendor' ? 'action_log' : 'nco_log',
  report_select: 'capa_plan_report',
  tab: role == 'action_owner' || role == 'vendor' ? '3' : '1',
  nco_table: [],
  audit_table: [],
  user_table: [],
  logs_search: [],
  reports_search: [],
  user_search: [],
  audit_search: [],
  audit: null,
  search_value: {
    CP_report_search: '',
    audit_search: '',
    CP_log_search: '',
    AI_log_search: '',
    CAPA_log_search: '',
    RCA_log_search: '',
    EC_log_search: '',
    CAPA_history_search: '',
    user_search: '',
    filtered_data: [],
  },
};

const ncoReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGS_DATA':
      if (action.dataType === state.log_select) {
        return {
          ...state,
          filtered_data: action.data,
          logs_search: [...new Set(action.data?.map((item) => item.nco_no))],
        };
      }
      return state;
    case 'SET_AUDIT_TABLE':
      return {
        ...state,
        audit_table: action.data,
        audit_search: [...new Set(action.data?.map((item) => item.nco_no))],
      };
    case 'SET_AUDIT_DATA':
      return {
        ...state,
        audit: action.data,
      };
    case 'SET_TAB':
      return {
        ...state,
        tab: action.data,
      };
    case 'SET_NCO_TABLE':
      return {
        ...state,
        nco_table: action.data,
        reports_search: [...new Set(action.data?.map((item) => item.nco_no))],
      };
    case 'SET_USER_TABLE':
      return {
        ...state,
        user_table: action.data,
        user_search: [...new Set(action.data?.map((item) => item.name))],
      };
    case 'SET_SELECT_LOG':
      const logData = JSON.parse(sessionStorage.getItem(action.data));
      return {
        ...state,
        logs: logData,
        logs_search: [...new Set(logData?.map((item) => item.nco_no))],
        log_select: action.data,
      };
    case 'SET_SELECT_REPORT':
      const reportsData = JSON.parse(sessionStorage.getItem(action.data));
      return {
        ...state,
        report_select: action.data,
        reports_search: [...new Set(reportsData?.map((item) => item.nco_no))],
      };

    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        search_value: action.data,
      };
    case 'RESET_NCO_REPORTS':
      return {
        logs: [],
        log_select:
          role == 'action_owner' || role == 'vendor' ? 'action_log' : 'nco_log',
        report_select: 'capa_plan_report',
        tab: role == 'action_owner' || role == 'vendor' ? '3' : '1',
        nco_table: [],
        audit_table: [],
        logs_search: [],
        reports_search: [],
        audit_search: [],
        audit: null,
        search_value: {
          CP_report_search: '',
          audit_search: '',
          CP_log_search: '',
          AI_log_search: '',
          CAPA_log_search: '',
          RCA_log_search: '',
          EC_log_search: '',
          CAPA_history_search: '',
        },
      };
    default:
      return state;
  }
};

export default ncoReportsReducer;
